import React, { ReactNode, Fragment, PropsWithChildren, useState } from "react";
import {
  colorGoldFill,
  colorGrayFill,
  colorGoldStroke,
  colorGrayStroke,
  bpSmall,
  bpMedium,
  bpLarge,
} from "../../stylingConstants";
import { css } from "@emotion/core";
import flourishNominatedBottom from "../../assets/flourishNominatedBottom.svg";
import flourishWinnerBottom from "../../assets/flourishWinnerBottom.svg";
import flourishNominatedTop from "../../assets/flourishNominatedTop.svg";
import flourishWinnerTop from "../../assets/flourishWinnerTop.svg";
import { Link } from "gatsby";
import { Heading4, Heading5, displayTypeface } from "../Atoms/Text";
import { verticalMargin } from "../../margins";
import Portrait from "../Atoms/Portrait";
import { ContentfulFluid } from "../../../graphql-schema-types";

type Props = {
  type: "winner" | "nominated" | "misc";
  title?: string;
  publication?: string | null;
  href?: string;
  authors?: {
    name?: string;
    image: Partial<ContentfulFluid>;
  }[];
};

const articleCss = (type: Props["type"], hoverEffect = false) => {
  const isAward = type !== "misc";
  const fillColor = type === "winner" ? colorGoldFill : colorGrayFill;
  const strokeColor = type === "winner" ? colorGoldStroke : colorGrayStroke;
  const hoverFillColor = type === "winner" ? "#F0E5D4" : "#E9E9EA";
  const flourishBottom =
    type === "winner" ? flourishWinnerBottom : flourishNominatedBottom;
  const flourishTop =
    type === "winner" ? flourishWinnerTop : flourishNominatedTop;
  return css`
    border-top: 2px solid ${strokeColor};
    background-color: ${fillColor};
    padding: 32px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 24px 12px;
    box-sizing: border-box;
    transition: background-color 250ms;
    transition-timing-function: ease-out;
    ${
      isAward &&
      css`
        background-image: url(${flourishTop});
        /* background-size: 50%; */
        background-position: top right;
        background-repeat: no-repeat;
      `
    }
    @media ${bpSmall} {
      padding: 24px;
      ${
        isAward &&
        css`
          min-height: 180px;
          background-image: url(${flourishBottom});
          background-position: bottom left;
        `
      } 
    }
    @media ${bpMedium} {
      padding: 32px;
    }
    @media ${bpLarge} {
      padding: 36px;
      ${
        isAward &&
        css`
          min-height: 258px;
        `
      }
    }
    ${
      hoverEffect &&
      css`
        :hover,
        .active {
          background-color: ${hoverFillColor};
          transition-timing-function: "ease-in";
        }
      `
    }
  `;
};

const titleCss = css`
  ${verticalMargin(4)};
  @media ${bpLarge} {
    margin-top: 8px;
    margin-bottom: 12px;
  }
`;

const publicationCss = css`
  font-weight: 400;
  margin: 0;
  font-family: ${displayTypeface};
  font-size: 17px;
  line-height: 1em;
  @media ${bpLarge} {
    font-size: 19px;
  }
`;

const columnCss = css`
  width: 100%;
  @media ${bpSmall} {
    width: 50%;
  }
`;

const portraitGridCss = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 12px;
  margin-bottom: -12px;
  @media ${bpSmall} {
    margin-top: -12px;
    justify-content: flex-end;
  }
`;

const portraitItemCss = css`
  width: 50%;
  margin-top: 12px;
  margin-bottom: 12px;
  text-align: center;
`;

export default function PresentationCard(props: Props) {
  const Wrapper =
    props.href != null
      ? ({ children }: PropsWithChildren<{}>) => (
          <Link to={props.href!}>
            {children}
          </Link>
        )
      : Fragment;
  const isWinner = props.type === "winner";
  const isAward = props.type !== "misc";
  return (
    <Wrapper>
      <article
        css={articleCss(props.type, props.href != null)}
      >
        <div css={columnCss}>
          {isAward && (
            <Heading5
              css={{
                color: isWinner ? colorGoldStroke : colorGrayStroke,
                textTransform: "uppercase",
              }}
            >
              {isWinner ? "Vinnare" : "Nominerad"}
            </Heading5>
          )}
          <Heading4 css={titleCss}>{props.title}</Heading4>
          <h6 css={publicationCss}>{props.publication}</h6>
        </div>
        <div css={[columnCss, portraitGridCss]}>
          {props.authors?.map((author) => (
            <div css={portraitItemCss} key={author.image?.src}>
              <Portrait
                name={author.name}
                image={author.image}
                tint={isWinner ? "gold" : "gray"}
              />
            </div>
          ))}
        </div>
      </article>
    </Wrapper>
  );
}
