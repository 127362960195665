import { extractWorkData, extractYearData } from "../../formatModels";
import React, { ReactNode, useCallback, PropsWithChildren } from "react";
import PresentationCard from "./PresentationCard";
import {
  MainCategoryHeading,
  AwardCategoryHeading,
} from "../Atoms/CategoryHeading";
import PortraitGrid from "./PortraitGrid";
import routeHelper from "../../routeHelper";

type EntryProps = {
  type: "winner" | "nominated";
  data: ReturnType<typeof extractWorkData>;
};

export const EntryPresentation = ({ data, type }: EntryProps) => (
  <PresentationCard
    key={data.title}
    type={type}
    title={data.title}
    publication={data.publishInfo}
    href={data.href}
    authors={data.authors.map((author) => ({
      name: author?.name ?? "",
      image: author?.image?.fluid!,
    }))}
  />
);

export const HostSpeakerAndJury = ({
  year,
  deemphasizedHeadings = false,
  juryRowCount = 4,
}: {
  year: ReturnType<typeof extractYearData>;
  deemphasizedHeadings?: boolean;
  juryRowCount?: Parameters<typeof PortraitGrid>[0]["maxNumberOfColumns"];
}) => {
  const Heading = useCallback(
    deemphasizedHeadings
      ? AwardCategoryHeading
      : ({ children }: PropsWithChildren<{}>) => (
          <MainCategoryHeading>
            {children} {year.year}
          </MainCategoryHeading>
        ),
    [deemphasizedHeadings]
  );
  return (
    <>
      {year.host && (
        <>
          <Heading>Programvärd</Heading>
          <PresentationCard
            key={year.host.id}
            type="misc"
            title={year.host.name ?? ""}
            href={routeHelper.getPersonInYearRoute(year.host!, year.year)}
            publication={year.host.name ?? ""}
            authors={[{ image: year.host.image?.fluid! }]}
          />
        </>
      )}
      {year.speakers && (
        <>
          <Heading>Gästtalare</Heading>
          {year.speakers.map((speaker) => (
            <PresentationCard
              type="misc"
              key={speaker?.id || ""}
              href={routeHelper.getPersonInYearRoute(speaker!, year.year)}
              title={speaker?.name ?? ""}
              publication={speaker?.name ?? ""}
              authors={[{ image: speaker?.image?.fluid! }]}
            />
          ))}
        </>
      )}
      {year.jury && (
        <>
          <Heading>Jury</Heading>
          <PortraitGrid
            maxNumberOfColumns={juryRowCount}
            people={year.jury.map((juror) => ({
              name: juror?.name ?? undefined,
              image: juror?.image?.fluid!,
              href: routeHelper.getPersonInYearRoute(juror!, year.year),
            }))}
          />
        </>
      )}
    </>
  );
};
