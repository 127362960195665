import {
  WorkSelectionFragment,
  YearSelectionFragment,
  AwardSelectionFragment,
} from "../graphql-schema-types";
import routeHelper from "./routeHelper";
import { capitalize, trimYearPrefix, awardOrder } from "./polymorphic-utils";

export const extractWorkData = ({
  image,
  publishInfo,
  juryQuote,
  ...source
}: WorkSelectionFragment) => ({
  ...source,
  publishInfo: publishInfo?.content?.[0]?.content?.[0]?.value,
  juryQuote: juryQuote?.content?.[0]?.content?.[0]?.value,
  image: image?.fluid,
  title: source.title || "",
  authors: source.authors || [],
  href: routeHelper.getWorkRoute(source),
});

export const extractAwardData = (input: AwardSelectionFragment) => ({
  ...input,
  title: capitalize(trimYearPrefix(input.title)),
  nominees:
    input.nominatedWorks
      ?.filter((work) => work)
      .map((work) => extractWorkData(work!)) ?? [],
  winner: input.winningWork && extractWorkData(input.winningWork),
});

const getAwardOrder = (awardTitle: string) => {
  const order = awardOrder.indexOf(awardTitle);
  return order != -1 ? order : awardOrder.length;
};

export const extractYearData = (input: YearSelectionFragment) => ({
  ...input,
  awards:
    input.awards != null
      ? input.awards
          .filter((award) => award)
          .map((award) => extractAwardData(award!))
          .sort(
            (award1, award2) =>
              getAwardOrder(award1.title) - getAwardOrder(award2.title)
          )
      : [],
});
